import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { registerComponents } from './plugins/loaderBaseComponents';

import SvgIcon from '@jamescoyle/vue-icon';

import './assets/tailwind-style.css';

const app = createApp(App);

registerComponents(app);

app.component('svgIcon', SvgIcon);

app
  .use(store)
  .use(router)
  .mount('#app');
