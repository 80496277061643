import { ApiAxios } from './api.config';

const RESOURCE = '/auth';

export default {
  async login(user_email, user_password) {
    return await ApiAxios().post(`${RESOURCE}/sign-in`, {
      user_email,
      password: user_password
    });
  },
  async checkIsAdmin() {
    return await ApiAxios().get(`/user/me`);
  }
};
