import { createRouter, createWebHistory } from 'vue-router';

const Login = () => import(/* webpackChunkName: "login" */ '../views/Login');
const HomeDashboard = () => import(/* webpackChunkName: "homeDashboard" */ '../views/Home.vue');
const ListPayments = () => import(/* webpackChunkName: "listPayments" */ '../views/ListPayments.vue');
const ListUsers = () => import(/* webpackChunkName: "ListUsers" */ '../views/ListUsers.vue');
const ProfileUser = () => import(/* webpackChunkName: "ProfileUser" */ '../views/ProfileUser.vue');
const Billing = () => import(/* webpackChunkName: "Billing" */ '../views/Billing.vue');
const ChargerbacksControl = () => import(/* webpackChunkName: "ChargerbacksControl" */ '../views/ChargerbacksControl.vue');

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: HomeDashboard
  },
  {
    path: '/payments',
    name: 'ListPayments',
    component: ListPayments,
    props: route => ({ page: parseInt(route.query.page ?? 1), limit: parseInt(route.query.limit ?? 100) })
  },
  {
    path: '/users',
    name: 'ListUsers',
    component: ListUsers,
    props: route => ({
      page: parseInt(route.query.page ?? 1),
      limit: parseInt(route.query.limit ?? 100),
      query: route.query.query ?? null
    })
  },
  {
    path: '/users/:user_id',
    name: 'ProfileUser',
    component: ProfileUser,
    props: route => ({ user_id: route.params.user_id })
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing
  },
  {
    path: '/chargerbacks-control',
    name: 'ChargerbacksControl',
    component: ChargerbacksControl
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
